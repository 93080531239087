import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppShare = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`🌤 Save our Future! 🙌

Forward this message to five top friends and groups, and let’s make a #FinalSay go viral!
CLICK ➡➡➡ http://saveourfuture.uk/share ⬅⬅⬅ HERE

Time is running out to save the UK from Brexit - take 30 seconds!
*WRITE to your MP:* 🗣 http://demandafinalsay.uk/ ⬅⬅⬅ `}
    />
    <Interstitial />
  </Layout>
);

WhatsAppShare.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppShare;

export const query = graphql`
  query ShareQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
